import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';
import CircularWithValueLabel from '../components/CircularWithValueLabel';
import axiosClient from '../axios-client';
import { ASSETS_PATH } from '../constants';
import AddHotspotsOnMap from '../components/AddHotspotsOnMap';
import sample_image from './../assets/map_1.png';





const Map = () => {

    const [mapPop, setMapPop] = useState(false);
    const { map_id } = useParams();
    const [mapData, setMapData] = useState({});
    const [spaces, setSpaces] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [activeHotspot, setActiveHotspot] = useState(null);
    const [activeHotspotIndex, setActiveHotspotIndex] = useState(null);

    const onCloseMapPop = () => {
        setActiveHotspot(null);
        setActiveHotspotIndex(null);
    }


    const onUpdateAndClosePop = (hotspot) => {

        setMapData(prev => {

            let hotspots = [...prev.map_hotspots_2d];
            hotspots[activeHotspotIndex] = hotspot;

            return {
                ...prev, map_hotspots_2d: hotspots
            }
        })

        setActiveHotspot(null);
        setActiveHotspotIndex(null);
    }

    const onOpenMapPop = (hotspot, index) => {
        setActiveHotspot(hotspot);
        setActiveHotspotIndex(index)
    }

    const onDeleteHotspot = (index) => {
        let hotspots = [...mapData.map_hotspots_2d];

        hotspots.splice(index, 1);

        setMapData(prev => {
            return { ...prev, map_hotspots_2d: hotspots }
        })

    }

    const onChangeUploadMapImage = (e) => {
        uploadAsset(e, (fileName) => {
            setMapData(d => {
                return { ...d, map_image_2d: fileName }
            })
        });
    }

    const onRemoveImage = () => {
        setMapData(d => {
            return { ...d, map_image_2d: "" }
        })
    }

    const onSaveMapData = () => {

        let payload = { ...mapData, map_hotspots_2d: JSON.stringify(mapData.map_hotspots_2d) }


        fetch(`${process.env.REACT_APP_API_URL}/map`, {
            method: "PUT",
            body: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((res) => res.json())
            .then(json => {
                alert(json.message);
            }).catch(err => {
                console.log(err)
            })
    }

    const uploadAsset = async (e, cb) => {
        let filename = "file.jpg";
        setUploading(true);

        let formData = new FormData();
        formData.append("file", e.target.files[0]);

        axiosClient.post(`${process.env.REACT_APP_API_URL}/upload-file`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: event => {
                console.log(event.loaded)
                setProgress(100 * event.loaded / event.total);
            }
        }).then((res) => {
            //console.log(res);
            cb(res.data.filename);
            setUploading(false);
        })

    }

    const onChangeMapName = (e) => {

        let name = e.target.value;

        setMapData(d => {
            return { ...d, map_name: name }
        })



    }

    const getSpaceNameById = (id) => {

        if (id === 0) {
            return "Untitled";
        }

        const space = spaces.find(s => s.space_id === id);

        if (space) {

            return space.space_name;
        } else {
            return "Untitled";
        }

    }

    const onAddRoute = () => {
        const newRoute = { space_id: 0, x: 0, y: 0, d_space_id: 0, dx: 0, dy: 0 };

        const hotspots = [...mapData.map_hotspots_2d];

        hotspots.push(newRoute);

        setMapData(prev => {
            return { ...prev, map_hotspots_2d: hotspots }
        });

        onOpenMapPop(newRoute, hotspots.length - 1);

    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/map/${map_id}`, { method: 'GET' })
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                setMapData(json.map);
            });

        fetch(`${process.env.REACT_APP_API_URL}/space`, { method: 'GET' })
            .then((res) => res.json())
            .then((json) => {
                setSpaces(json.spaces);
            });
    }, [])

    return (
        <>
            <div className="content-body">
                <form>
                    <TextField className='textfield w-full' value={mapData.map_name} onChange={onChangeMapName} />
                    <br />
                    <br />
                    {mapData.map_image_2d && <>
                        <img src={`${ASSETS_PATH}/${mapData.map_image_2d}`} style={{ width: '400px' }} />
                        <br />
                        <br />
                    </>
                    }
                    {!mapData.map_image_2d && <Button
                        variant="contained"
                        component="label"
                        style={{ marginRight: "10px" }}
                    >
                        *Upload Image
                        <input
                            type="file"
                            hidden
                            onChange={onChangeUploadMapImage}
                        />
                    </Button>}

                    {mapData.map_image_2d &&
                        <>
                            <Button variant='contained' onClick={onRemoveImage}>Remove Image</Button>
                        </>}
                    <br />
                    <br />
                    <Button variant='contained' onClick={onAddRoute}>Add new route</Button>
                    <br />
                    <br />
                    <table className="table-auto border w-full">
                        <thead>
                            <tr>
                                <th className="font-bold p-2 border-b text-left w-2">No</th>
                                <th className="font-bold p-2 border-b text-left">Hotspot Name</th>
                                <th className="font-bold py-2 px-4 border-b text-left">Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                            {mapData.map_hotspots_2d && mapData.map_hotspots_2d.map((hotspot, index) => <tr key={index}>
                                <td className="p-2 border-b text-left">{index + 1}</td>
                                <td className="p-2 border-b text-left">{getSpaceNameById(hotspot.scene_id)}</td>
                                <td className="py-2 px-4 border-b text-left">
                                    <Button onClick={() => { onOpenMapPop(hotspot, index) }}>Edit</Button>
                                    <Button onClick={() => { onDeleteHotspot(index) }}>Delete</Button>
                                </td>
                            </tr>)}

                        </tbody>
                    </table>

                    <hr />
                    <br />

                    <Button variant='contained' onClick={onSaveMapData}>Save</Button>

                </form>
            </div>
            {activeHotspot &&
                <AddHotspotsOnMap
                    onCloseMapPop={onCloseMapPop}
                    mapData={mapData}
                    hotspot={activeHotspot}
                    spaces={spaces}
                    onUpdateAndClosePop={onUpdateAndClosePop}
                    uploadAsset={uploadAsset}
                />
            }
            {uploading && <CircularWithValueLabel progress={progress} />}
        </>

    )
}

export default Map;