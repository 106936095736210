import { Button, TextField } from "@mui/material";
import "./../styles/PopLanguages.scss";
import { useState } from "react";

const PopLanguages = ({ fieldName, languages, onSetLs, onLangPopClose }) => {
  const [langs, setLangs] = useState(languages);

  const onChangeHindi = (e) => {

    setLangs(prevLangs => {
        return {...prevLangs, hindi:e.target.value}
    })

  }

  const onChangeMarathi = (e) => {
    setLangs(prevLangs => {
        return {...prevLangs, marathi:e.target.value}
    })
  }

  return (
    <div open id="popup-languages">
      <div className="pop-background"></div>
      <div className="pop-container">
        <div className="pop-header">Input {fieldName} in other languages</div>
        <div className="pop-body flex flex-col">
          <br />
          <TextField 
          label="Hindi" 
          value={langs.hindi || ""} 
          onChange={onChangeHindi}
          />
          <br />
          <br />
          <TextField 
          label="Marathi" 
          value={langs.marathi || ""} 
          onChange={onChangeMarathi}
          />
          <br />
          <br />
        </div>
        <div className="pop-footer">
          <Button
            variant="contained"
            className="!mr-6"
            onClick={() => onSetLs(langs)}
          >
            Update and Close
          </Button>
          <Button onClick={onLangPopClose}>Cancel</Button>
        </div>
      </div>
    </div>
  );
};

export default PopLanguages;
