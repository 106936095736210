import './../styles/AddHotspotsOnMap.scss';
import { HiMapPin } from "react-icons/hi2";
import { useEffect, useState } from 'react';
import { Select, MenuItem, Button, FormControl, InputLabel, Tooltip } from '@mui/material';
import { ASSETS_PATH } from '../constants';


let selectedHotspot = null;
let gHotspots = [];
let mapHeight = 0;

const AddHotspotsOnMap = ({ onCloseMapPop, mapData, hotspot, spaces, onUpdateAndClosePop, uploadAsset }) => {

    const [activeHotspot, setActiveHotspot] = useState(hotspot);


    const onHotspotDown = (e) => {


        selectedHotspot = e.currentTarget;

        //selectedHotspot.style.fill = 'red';
        document.addEventListener('mousemove', onHotspotMove);


    }


    const onHotspotMove = (e) => {
        const left = parseInt(selectedHotspot.style.left);
        const top = parseInt(selectedHotspot.style.top);

        let newLeft = e.movementX + left;

        newLeft = newLeft < 0 ? 0 : newLeft;
        newLeft = newLeft > 800 ? 800 : newLeft;

        let newTop = e.movementY + top;

        newTop = newTop < 0 ? 0 : newTop;
        newTop = newTop > mapHeight ? mapHeight : newTop;

        selectedHotspot.style.left = newLeft + 'px';
        selectedHotspot.style.top = newTop + 'px';



        if (selectedHotspot.getAttribute('type') == 'start') {
            console.log("in start")
            setActiveHotspot(prev => {
                return { ...prev, x: newLeft, y: newTop }
            })
        } else {
            console.log("in target")
            setActiveHotspot(prev => {
                return { ...prev, dx: newLeft, dy: newTop }
            })
        }


    }




    const onChangeStartSceneID = (e) => {

        const id = e.target.value;

        setActiveHotspot(prev => {
            return { ...prev, scene_id: id }
        });


    }

    const onChangeTargetSceneID = (e) => {

        const id = e.target.value;

        setActiveHotspot(prev => {
            return { ...prev, d_scene_id: id }
        });


    }


    const onChangeRoute = (e) => {
        uploadAsset(e, (fileName) => {
            setActiveHotspot(h => {
                return { ...h, route: fileName }
            })
        });
    }


    const onRemoveRoute = () => {
        setActiveHotspot(prev => {
            return {...prev, route:''}
        })
    }

    const onUpdateAndClose = () => {

        onUpdateAndClosePop(activeHotspot);
    }

    useEffect(() => {

        setMapHeight();

        setTimeout(() => {
            setMapHeight();
        }, 1000);

        setTimeout(() => {
            setMapHeight();
        }, 2000);

        setTimeout(() => {
            setMapHeight();
        }, 3000);

        document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', onHotspotMove);
            selectedHotspot = null;
        });

    });

    const setMapHeight = () => {
        if(document.getElementById('map')){
            mapHeight = document.getElementById('map').offsetHeight;
            document.getElementById('hotspots-body').style.height = mapHeight + 'px';
        }
    }


    return (
        <>
            <div className="pop-map-background"></div>
            <div className='pop-map'>
                <div className='pop-header'>
                    <div className='map-name'>
                        Place Route
                    </div>
                    <div>
                        {activeHotspot &&
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 220, backgroundColor: 'rgba(128, 0, 128, 0.3)' }}>
                                <InputLabel id="demo-simple-select-standard-label">Select start location</InputLabel>
                                <Select label="Select current location" value={activeHotspot.scene_id} onChange={onChangeStartSceneID}>
                                    <MenuItem value={0}>Select current location</MenuItem>
                                    {spaces.map((s, index) => <MenuItem key={index} value={s.space_id}>{s.space_name}</MenuItem>)}

                                </Select>
                            </FormControl>
                        }

                        {activeHotspot &&
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 220, backgroundColor: 'rgba(255, 0, 0, 0.3)' }}>
                                <InputLabel id="demo-simple-select-standard-label">Select target location</InputLabel>
                                <Select label="Select target location" value={activeHotspot.d_scene_id} onChange={onChangeTargetSceneID}>
                                    <MenuItem value={0}>Select target location</MenuItem>
                                    {spaces.map((s, index) => <MenuItem key={index} value={s.space_id}>{s.space_name}</MenuItem>)}

                                </Select>
                            </FormControl>
                        }
                    </div>
                </div>
                <div className='pop-body'>
                    <div className='hotspots-body' id='hotspots-body'>
                        <img src={`${ASSETS_PATH}/${mapData.map_image_2d}`} id="map" />
                        {activeHotspot.route && <img src={`${ASSETS_PATH}/${activeHotspot.route}`} id="route" />}
                        <HiMapPin
                            className='hotspot'
                            type="start"
                            onMouseDown={(e) => { onHotspotDown(e) }}
                            style={{
                                top: activeHotspot.y + 'px',
                                left: activeHotspot.x + 'px',
                                fill: 'purple'
                            }}
                        />
                        <HiMapPin
                            className='hotspot'
                            type="target"
                            onMouseDown={(e) => { onHotspotDown(e) }}
                            style={{
                                top: activeHotspot.dy + 'px',
                                left: activeHotspot.dx + 'px',
                                fill: 'red'
                            }}
                        />

                    </div>
                </div>
                <div className='pop-footer'>
                    {!activeHotspot.route && <Button
                        
                        component="label"
                        style={{ marginRight: "10px" }}
                    >
                        *Upload Route
                        <input
                            type="file"
                            hidden
                            onChange={onChangeRoute}
                        />
                    </Button>}

                    {activeHotspot.route && <Button
                        
                        style={{ marginRight: "10px" }}
                        onClick={onRemoveRoute}
                    >
                        Remove Route
                        
                    </Button>}
                    <Button onClick={onUpdateAndClose}>Update and Close</Button>
                    <Button variant='contained' onClick={onCloseMapPop}>Close</Button>
                </div>
            </div>
        </>
    )
}

export default AddHotspotsOnMap;